import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { InfiniteScroll } from 'Common/components/ui';
import { catalog, customer, order } from '~features';
import { useAction } from 'Common/hooks';
import CatalogCard from './CatalogCard';

export default function CatalogGrid({ skipChildEnrichment = false, ...props }) {
    const items = useSelector(catalog.selectors.getFilteredProducts);
    const pageInfo = useSelector(catalog.selectors.getPageInfo);
    const isUserAuthenticated = useSelector(customer.selectors.isLoggedIn);
    const loadMore = useAction(catalog.actions.loadMore);
    const onAddMultipleToCart = useAction(order.actions.addItems);
    const isDoneLoading = React.useMemo(() => pageInfo.page * pageInfo.pageSize >= pageInfo.total, [pageInfo]);

    const onLoadMore = React.useCallback(() => loadMore(skipChildEnrichment), [loadMore, skipChildEnrichment]);

    return (
        <div className="CatalogGrid">
            <InfiniteScroll isDoneLoading={isDoneLoading} onLoad={onLoadMore}>
                <div className="row">
                    {items?.map((product) => (
                        <CatalogCard
                            key={product.Code}
                            product={product}
                            onAddMultipleToCart={onAddMultipleToCart}
                            isUserAuthenticated={isUserAuthenticated}
                            {...props}
                        />
                    ))}
                </div>
            </InfiniteScroll>
        </div>
    );
}

CatalogGrid.propTypes = {
    ctaModel: px.objectOf(px.any),
    checkoutPageLink: px.string,
    gtmListValue: px.string,
    viewDetails: px.string,
    skipChildEnrichment: px.bool,
};
